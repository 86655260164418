import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Portfolio from './Portfolio';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component
import Projects from './Projects';
import Skills from './Skills';

function App() {
  return (
    <HelmetProvider>
      {/* Global Metadata */}
      <Helmet>
        <title>Widmison Francois - Web Developer Portfolio</title>
        <meta
          name="description"
          content="Explore the portfolio of Widmison Francois, a professional web and software developer. See innovative projects, skills, and services."
        />
        <meta
          name="keywords"
          content="Widmison Francois, Web Developer, Portfolio, Software Developer, Innovative Projects, Scalable Software"
        />
        <meta name="author" content="Widmison Francois" />
      </Helmet>
    <Router>
      <ScrollToTop /> {/* Ensures the page scrolls to top on navigation */}
      <Header />
      <Routes>
        {/* Home Page: Includes WelcomeSection, Pricing, Testimonials */}
        <Route
          path="/"
          element={
            <>
              <Portfolio />
              <Skills/>
              <Projects />
            </>
          }
        />
      </Routes>
      <Footer />
    </Router>
    </HelmetProvider>
  );
}

export default App;
