import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import './Portfolio.css';
import Skills from './Skills';
import Projects from './Projects';

const Portfolio = () => {
  const navigate = useNavigate();

  return (
    <HelmetProvider>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>Widmison Francois - Web and Software Developer Portfolio</title>
        <meta
          name="description"
          content="Discover the professional portfolio of Widmison Francois, a web and software developer specializing in innovative and user-friendly applications. Explore projects, skills, and services."
        />
        <meta
          name="keywords"
          content="Widmison Francois, Web Developer, Software Developer, Portfolio, Innovative Applications, Scalable Software, User-Friendly Websites"
        />
        <meta name="author" content="Widmison Francois" />
        <meta property="og:title" content="Widmison Francois - Web and Software Developer" />
        <meta
          property="og:description"
          content="Explore the portfolio of Widmison Francois, showcasing expertise in web and app development projects across various industries."
        />
        <meta property="og:image" content="https://yourwebsite.com/img/hero-2.png" />
        <meta property="og:url" content="https://yourwebsite.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Widmison Francois - Web and Software Developer" />
        <meta
          name="twitter:description"
          content="Discover the professional portfolio of Widmison Francois, a web and software developer specializing in innovative and user-friendly applications."
        />
        <meta name="twitter:image" content="https://yourwebsite.com/img/hero-2.png" />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Widmison Francois",
            "jobTitle": "Web and Software Developer",
            "url": "https://yourwebsite.com",
            "sameAs": [
              "https://www.linkedin.com/in/widmison-francois-023185164/",
              "https://github.com/Widmison",
              "https://www.instagram.com/widmison/?hl=en"
            ],
            "description": "Widmison Francois is a skilled web and software developer with expertise in building innovative, scalable, and user-friendly applications and websites.",
            "image": "https://yourwebsite.com/img/hero-2.png",
            "worksFor": {
              "@type": "Organization",
              "name": "Royol Technology"
            }
          }
          `}
        </script>
      </Helmet>

      {/* Welcome Section */}
      <section className="welcome-section-portfolio">
        <div className="welcome-container-portfolio">
          <div className="welcome-text-portfolio">
            <h1 className="hero-text-portfolio">Welcome to Widmison Francois' Portfolio</h1>
            <p className="hero-p-portfolio">
              Widmison Francois is a highly skilled and results-driven Web and Software Developer with extensive experience in building innovative,
              scalable, and user-friendly software applications and websites. With a passion for clean code, modern design, and cutting-edge technologies,
              Widmison has a proven track record of delivering high-quality projects that meet client expectations and business goals.
            </p>

            <div className="explore-buttons-portfolio">
              <button
                className="explore-button-portfolio"
                onClick={() => navigate('/quote')}
              >
                Contact Me
              </button>
              <button
                className="explore-button-portfolio"
                onClick={() => navigate('/projects')}
              >
                See My Projects
              </button>
              <button
                className="explore-button-portfolio"
                onClick={() => navigate('/apps')}
              >
                See My Apps
              </button>
            </div>
          </div>
          <div className="welcome-image-portfolio">
            <img
              alt="Widmison Francois - Dynamic Digital Experiences"
              src="img/hero-2.png"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Portfolio;